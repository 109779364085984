<template>
  <v-app dark>
    <v-navigation-drawer clipped app v-model='drawer' v-if='isLogged()'>
      <v-list dense>
        <v-list-item
          v-for='item in availableMenuItems'
          :key='item.title'
          :to='item.link'
          link
        >
          <v-list-item-icon>
            <v-icon :class="{'red--text': item.attention}">{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title :class="{'red--text': item.attention}">
              {{ item.title }}
              <v-icon color='red' class="ml-3" v-if="item.attention">mdi-alert-octagram</v-icon>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar clipped-left app dark color='primary'>
      <v-app-bar-nav-icon class='hidden-lg-and-up' v-if='isLogged()' @click='drawer = true'></v-app-bar-nav-icon>
      <v-spacer></v-spacer>
      <v-btn v-if='isLogged()' @click='showMessage = !showMessage' icon x-large >
        <v-badge :value='message != ""' content='1' color='orange' overlap>
          <v-icon>
            mdi-message-text
          </v-icon>
        </v-badge>
      </v-btn>
      <v-menu
        bottom
        min-width="200px"
        rounded
        offset-y
        v-if='isLogged()'
      >
        <template v-slot:activator="{ on }">
          <v-btn
            icon
            x-large
            v-on="on"
          >
              <v-icon>
                mdi-account-circle
              </v-icon>
          </v-btn>
        </template>
        <v-card>
          <v-list-item-content class="justify-center">
            <div class="mx-auto text-center">
              <v-avatar
                color="primary"
                class='elevation-1'
              >
                <v-icon dark>
                  mdi-account-circle
                </v-icon>
              </v-avatar>
              <h3 class='mt-3'>
                {{getTwoNames(name)}}
              </h3>
              <p class="caption mt-1">
                {{email}}
              </p>
              <v-divider class="my-3"></v-divider>
              <v-btn
                depressed
                rounded
                text
                to='settings'
              >
                Настройки
              </v-btn>
              <v-divider class="my-3"></v-divider>
              <v-btn
                depressed
                rounded
                text
                @click='logout'
              >
                Изход
              </v-btn>
            </div>
          </v-list-item-content>
        </v-card>
      </v-menu>
    </v-app-bar>

    <!-- Sizes your content based upon application components -->
    <v-main>

      <!-- Provides the application the proper gutter -->
      <v-container fill-height fluid>

        <!-- If using vue-router -->
        <router-view v-if='isLogged()'></router-view>
        <LoginForm :loading='logging' :incorrectAttempt='loginIncorrectAttempt' v-if='!isLogged()' @login='login'></LoginForm>
      </v-container>
    </v-main>
    <v-snackbar v-model='showMessage' v-if='message != ""' timeout='-1' vertical centered>
      <div class='text-h6'>Съобщение:</div>
      <div v-if='$store.state.hasUnreadNotices' class='text-body-1 red--text'>
        Имате непрочетени съобщения
      </div>
      <div>
        {{ message }}
      </div>

      <template v-slot:action="{ attrs }">
        <v-btn
          color="primary"
          text
          v-bind="attrs"
          @click="showMessage = false"
        >
          Затвори
        </v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>

<script>
import LoginForm from './components/LoginForm';

export default {
  name: 'App',

  components: {
    LoginForm,
  },
  created: function () {
    this.getDarkState()
  },
  data: function () {
    return {
      drawer: true,
      apiKey: 54321,
      logging: false,
      loginIncorrectAttempt: false,
      email: null,
      name: '... ...',
      message: null,
      showMessage: false,
      userFlags: []
    }
  },
  computed: {
    loginKey () {
      return this.$store.state.loginKey
    },
    staffId () {
      return this.$store.state.staffId
    },
    isToolboxExpiring () {
      if (this.$store.state.toolboxExpiryDate == "")
        return false
      
      let expiryDate = new Date(this.$store.state.toolboxExpiryDate)

      let today = new Date()
      today.setHours(expiryDate.getHours())
      today.setMinutes(expiryDate.getMinutes())
      today.setSeconds(expiryDate.getSeconds())
      today.setMilliseconds(expiryDate.getMilliseconds())

      let timeDiff = expiryDate.getTime() - today.getTime();

      let daysDiff = Math.round(timeDiff / (1000 * 3600 * 24))

      return daysDiff <= 30
    },
    menuItems () {
      return [
        { title: 'Разпределение', icon: 'mdi-view-dashboard', link: 'distribution' },
        { title: 'Присъствие', icon: 'mdi-table', link: 'presence' },
        { title: 'Отработени часове', icon: 'mdi-account-clock', link: 'workhours' },
        { title: 'Фишове', icon: 'mdi-file-document', link: 'payslips' },
        { title: 'Документи за сваляне', icon: 'mdi-download', link: 'downloads' },
        { title: 'Management Notices', icon: 'mdi-text-box-outline', link: 'notices' },
        { title: 'CEO Ordinance', icon: 'mdi-text-box-outline', link: 'ordinance' },
        { title: 'Activity Log', icon: 'mdi-view-list', link: 'activitylog' },
        { title: 'Civil Contracts', icon: 'mdi-account-clock', link: 'civilcontracts', requiredFlag: 'civil-contractor'},
        { title: 'Voluntary Report', icon: 'mdi-text-box-outline', link: 'voluntaryoccurrence', requiredFlag: 'report-creator'},
        { title: 'Non Conformity Report', icon: 'mdi-text-box-outline', link: 'nonconformity', requiredFlag: 'report-creator'},
        { title: 'Toolbox Daily Check', icon: 'mdi-toolbox', link: 'toolboxdailycheck', attention: this.isToolboxExpiring, requiredFlag: 'has-toolboxes' },
        { title: 'Safety Report', icon: 'mdi-hard-hat', link: 'safetyreport', requiredFlag: 'report-creator'},
      ]
    },
    availableMenuItems () {
      return this.menuItems.filter(menuItem => {
        if ('requiredFlag' in menuItem) {
          if (!this.userFlags.includes(menuItem.requiredFlag))
            return false
        }

        if (this.$store.state.hasUnreadNotices) {
          if (!['ordinance', 'notices', 'toolboxdailycheck'].includes(menuItem.link)) {
            return false
          }
        }

        return true
      })
    }
  },
  methods: {
    isLogged: function () {
      return (this.loginKey != null) && (this.staffId !=null)
    },
    logout: function () {
      this.$store.state.loginKey = null
      this.email = null
      this.name = '... ...'
      this.message = null
      this.showMessage = false
      this.userFlags = []
    },
    login: function (username, password) {
      this.logging = true
      var loginData = new FormData()
      loginData.append('apiKey', this.apiKey)
      loginData.append('username', username)
      loginData.append('password', password)
      this.$http.post('https://t-service.bg/atbg/api/get_login_key', loginData).then((response) => {
        this.logging = false
        if(response.data.length > 0) {
          this.loginIncorrectAttempt = false
          this.$store.state.loginKey = response.data[0].login_key
          this.getUserData(response.data[0].login_key, () => {
            this.checkForUnreadNotices()
          })
        }
        else {
          this.loginIncorrectAttempt = true
        }
      })
    },
    getUserData: function (key, cb = () => {}) {
      var loginData = new FormData()
      loginData.append('apiKey', this.apiKey)
      loginData.append('key', this.loginKey)
      this.$http.post('https://t-service.bg/atbg/api/get_user_data', loginData).then((response) => {
        this.email = response.data[0].mail
        this.name = response.data[0].name
        this.message = response.data[0].message
        this.$store.state.staffId = response.data[0].id
        this.$store.state.userNameEn = response.data[0].name_lat
        this.$store.state.userNote = response.data[0].note
        if (this.message != '')
          this.showMessage = true

        if (parseInt(response.data[0].flag_cc))
          this.userFlags.push('civil-contractor')
        
        if (parseInt(response.data[0].flag_reports))
          this.userFlags.push('report-creator')

        const getStaffToolBoxesData = new FormData()
        getStaffToolBoxesData.append("staff_id", response.data[0].id)
        this.$http.post('https://inv.bgair.eu/app/a8/index.php?ep=getStaffToolboxes', getStaffToolBoxesData).then((response) => {
          if (response.data[0].result > 0)
              this.userFlags.push('has-toolboxes')

          this.$store.state.lastDailyCheck = response.data[0]['last_check'] === "1"
          this.$store.state.toolsetNumber = response.data[0]['toolset_num']
          this.$store.state.toolboxExpiryDate = response.data[0]['expiry_date']
        })

        cb()
      })
    },
    getTwoNames: function (name) {
      var names = name.split(' ')
      return names[0]+' '+names[names.length-1]
    },
    getDarkState: function () {
      if (localStorage.getItem('isDark') == null)
        localStorage.setItem('isDark', false)
      
      this.$vuetify.theme.dark = localStorage.getItem('isDark') == 'true' ? true : false
    },
    checkForUnreadNotices: function () {
      this.$store.state.hasUnreadNotices = false
      let linkToGo = ''

      let req1 = this.$http.get('https://inv.bgair.eu/app/a8g/index.php?ep=mm_notes2&staff_id='+this.staffId).then((response) => {
        let containsUnread = (response.data.filter(notice => {return notice.read == "0"}).length > 0)
        if (containsUnread) {
          console.log('notices has unread')
          this.$store.state.hasUnreadNotices = true
          linkToGo = 'notices'
        }
      })

      let req2 = this.$http.get('https://inv.bgair.eu/app/a8g/index.php?ep=ceo_ordinance2&staff_id='+this.staffId).then((response) => {
        let containsUnread = (response.data.filter(notice => {return notice.read == "0"}).length > 0)
        if (containsUnread) {
          console.log('oridinance has unread')
          this.$store.state.hasUnreadNotices = true
          linkToGo = 'ordinance'
        }
      })

      Promise.all([req1, req2]).then(() => {
        if (this.$store.state.hasUnreadNotices)
          this.$router.push(`/${linkToGo}`)
      })
    }
  }
};
</script>
<style>
/* .v-application--wrap {
  min-height: initial !important;
} */

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active  {
    /* -webkit-box-shadow: 0 0 0 30px transparent inset !important; */
    transition: background-color 5000s ease-in-out 0s;
}

label.theme--dark+input {
  -webkit-text-fill-color: white !important;
}
</style>