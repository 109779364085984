<template>
    <v-container>
        <v-speed-dial absolute v-model="helpMenu" bottom right direction="top" :open-on-hover="true" transition="slide-y-reverse-transition">
        <template v-slot:activator>
          <v-btn v-model="helpMenu" color="orange" dark fab large>
            <v-icon v-if="helpMenu">
              mdi-close
            </v-icon>
            <v-icon v-else>
              mdi-help
            </v-icon>
          </v-btn>
        </template>
        <v-btn target="_blank" href="https://atbgstaff.bgair.eu/safety_report_example.pdf" fab large color="blue" dark>
          <v-icon>mdi-file</v-icon>
        </v-btn>
        <v-btn target="_blank" href="https://atbgstaff.bgair.eu/instructions_safety_report.pdf" fab large color="green" dark>
          <v-icon>mdi-information-variant</v-icon>
        </v-btn>
      </v-speed-dial>
        <v-row justify='center' cols='12'>
            <v-col cols='12' class='text-center'>
                <div class="text-h5 text-md-h4 text-lg-h3 font-weight-bold mb-3">
                    Safety Report
                </div>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                The single aim of the information reported on this form is to improve safety. You are not obliged to give your identity and position in the organisation. However, should you wish to do so, they will not be disclosed without your approval.
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" md="6">
                <v-form ref="form">
                    <v-select
                        :loading="dataFetching"
                        :disabled="dataFetching"
                        :items="aircraftRegistrations"
                        item-value="id"
                        v-model="selectedAircraftRegistrationId"
                        label="Aircraft Registration"
                    ></v-select>

                    <v-menu
                        ref="dateOfEventMenu"
                        v-model="dateOfEventMenu"
                        :close-on-content-click="false"
                        :return-value.sync="dateOfEvent"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                        >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                            v-model="dateOfEvent"
                            label="Date of Event*"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            ></v-text-field>
                        </template>
                        <v-date-picker
                            v-model="dateOfEvent"
                            no-title
                            scrollable
                        >
                            <v-spacer></v-spacer>
                            <v-btn
                            text
                            color="primary"
                            @click="dateOfEventMenu = false"
                            >
                            Cancel
                            </v-btn>
                            <v-btn
                            text
                            color="primary"
                            @click="$refs.dateOfEventMenu.save(dateOfEvent)"
                            >
                            OK
                            </v-btn>
                        </v-date-picker>
                    </v-menu>

                    <v-text-field
                        v-model="reporterDetails"
                        label="Reporter details (Names, Auth.№)"
                    ></v-text-field>

                    <v-text-field
                        v-model="locationOfEvent"
                        label="Location of Event"
                    ></v-text-field>

                    <v-select
                        :loading="dataFetching"
                        :disabled="dataFetching"
                        :items="operationTypes"
                        item-value="id"
                        v-model="selectedOperationTypeId"
                        label="Type of process (Safety)"
                    ></v-select>

                    <v-alert type="info">
                        <div>Classification of event:</div>
                        <div class="caption">(Choose any of the possibilities if is is related with a nonconformity)</div>
                    </v-alert>

                    <v-text-field
                        v-model="documentation"
                        label="Documentation"
                    ></v-text-field>

                    <v-text-field
                        v-model="tools"
                        label="Tools"
                    ></v-text-field>

                    <v-select
                        :loading="dataFetching"
                        :disabled="dataFetching"
                        :items="others"
                        item-value="id"
                        v-model="selectedOtherId"
                        label="Others"
                    ></v-select>
                </v-form>
            </v-col>
            <v-col cols="12" md="6">
                <v-textarea
                    outlined
                    v-model="description"
                    label="Description of the event*"
                    persistent-hint
                    class="mt-6"
                    hint="Explain how the event occurred, why it occurred and why it did not result in an accident"
                ></v-textarea>

                <v-textarea
                    outlined
                    v-model="suggestions"
                    label="Suggestions"
                    persistent-hint
                    class="mt-6"
                    hint="What are your suggestions to prevent this event from re-occurring or for preventing that such an event could result in an accident?"
                ></v-textarea>

                <v-select
                    :loading="dataFetching"
                    :disabled="dataFetching"
                    :items="riskProbabilities"
                    item-value="id"
                    v-model="selectedRiskProbabilityId"
                    label="Recurrence Probability"
                    persistent-hint
                    class="mt-6"
                    hint="In your opinion, what is the likelihood of such an event or similar one happening again?"
                ></v-select>

                <v-select
                    :loading="dataFetching"
                    :disabled="dataFetching"
                    :items="riskSeverities"
                    item-value="id"
                    v-model="selectedRiskSeverityId"
                    label="Risk Severity"
                    persistent-hint
                    class="mt-6"
                    hint="What do you consider to be the worst possible consequence if this event happened again?"
                ></v-select>

                <v-file-input
                    v-model="attachedFiles"
                    prepend-icon=""
                    append-icon="mdi-paperclip"
                    outlined
                    multiple
                    label="Attached files"
                    truncate-length="15"
                    class="mt-6"
                ></v-file-input>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" lg="2">
                <v-btn :disabled="dataFetching || !dataValid" block color="warning" @click="submitForm" :loading="submittingForm">Send Report</v-btn>
            </v-col>
            <v-col cols="12" lg="2">
                <v-btn color="error" block outlined @click="$router.push('/')">Exit without saving</v-btn>
          </v-col>
        </v-row>
    </v-container>
</template>

<script>
    export default {
        name: 'SafetyReport',
        data: function () {
            return {
                dataFetching: false,

                aircraftRegistrations: [],
                selectedAircraftRegistrationId: "0",

                operationTypes: [],
                selectedOperationTypeId: "7",

                others: [],
                selectedOtherId: "1",

                riskProbabilities: [],
                selectedRiskProbabilityId: "0",

                riskSeverities: [],
                selectedRiskSeverityId: "0",

                dateOfEventMenu: false,
                dateOfEvent: null,

                reporterDetails: "",

                locationOfEvent: "",

                documentation: "",

                tools: "",

                description: "",

                suggestions: "",

                attachedFiles: [],

                submittingForm: false,

                helpMenu: false
            }
        },
        methods: {
            fetchData() {
                this.dataFetching = true
                let aircraftRegistrationsPromise = this.$http.get(`https://inv.bgair.eu/app/a8g/index.php?ep=AutoFilter&t=aircraft&c=registration&k=id&w=undefined`).then((response) => {
                    this.aircraftRegistrations = response.data
                })
                let operationTypesPromise = this.$http.get(`https://inv.bgair.eu/app/a8g/index.php?ep=AutoFilter&t=type_of_operation&c=name&k=id&w=undefined`).then((response) => {
                    this.operationTypes = response.data
                })
                let othersPromise = this.$http.get(`https://inv.bgair.eu/app/a8g/index.php?ep=AutoFilter&t=others_safety_report&c=name&k=id&w=undefined`).then((response) => {
                    this.others = response.data
                })
                let riskProbabilitiesPromise = this.$http.get(`https://inv.bgair.eu/app/a8g/index.php?ep=AutoFilter&t=safety_risk_probability&c=name&k=id&w=undefined`).then((response) => {
                    this.riskProbabilities = response.data
                })
                let riskSeveritiesPromise = this.$http.get(`https://inv.bgair.eu/app/a8g/index.php?ep=AutoFilter&t=safety_risk_severity&c=name&k=id&w=undefined`).then((response) => {
                    this.riskSeverities = response.data
                })
                Promise.allSettled([
                    aircraftRegistrationsPromise,
                    operationTypesPromise,
                    othersPromise,
                    riskProbabilitiesPromise,
                    riskSeveritiesPromise
                ]).then(() => {
                    this.dataFetching = false
                })
            },
            submitForm () {
                if (!this.dataValid)
                    return

                this.submittingForm = true

                const formData = new FormData()
                formData.append('aircraft_id', this.selectedAircraftRegistrationId)
                formData.append('event_description', this.description)
                formData.append('event_date', this.description)
                formData.append('event_operation', "")
                formData.append('event_location', this.locationOfEvent)
                formData.append('documentation', this.documentation)
                formData.append('tools', this.tools)
                formData.append('others_safety_report_id', this.selectedOtherId)
                formData.append('suggestions', this.suggestions)
                formData.append('event_classification_id', "0")
                formData.append('reporter_details', this.reporterDetails)
                formData.append('safety_risk_probability_id', this.selectedRiskProbabilityId)
                formData.append('safety_risk_severity_id', this.selectedRiskSeverityId)
                formData.append('type_of_operation_id', this.selectedOperationTypeId)
                this.attachedFiles.forEach(file => {
                    formData.append("files[]", file, file.name)
                })

                this.$http.post("https://apih1.aerotechnic-bg.com/safety_upload_external/", formData).then(() => {
                    this.$router.push('/')
                    this.submittingForm = false
                })
            }
        },
        computed: {
            // staffId () {
            //   return this.$store.state.staffId
            // }

            dataValid () {

                if (this.dateOfEvent == null)
                    return false

                if (this.description.length < 3)
                    return false

                return true
            }

        },
        created () {
            this.fetchData()
        }
    }
</script>