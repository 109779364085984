<template>
  <v-row>
    <v-col cols='12'>
      <v-row justify='center' cols='12'>
        <v-col cols='12' class='text-center'>
          <div class="text-h5 text-md-h4 text-lg-h3 font-weight-bold mb-3">
            Фишове за последните три месеца
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols='12' md='4'>
          <v-card>
            <v-card-title>
              {{lastThreeMonths[0]}}
            </v-card-title>
            <v-card-subtitle>
              Сваляне на фиша в PDF
            </v-card-subtitle>
            <v-card-text>
              <v-card outlined>
                <v-card-text v-html='lastThreeMonthsStats[0]'>
                </v-card-text>
              </v-card>
            </v-card-text>
            <v-card-actions>
              <v-btn :href='genLink(0)' target='_blank' block color='primary'>Свали</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col cols='12' md='4'>
          <v-card>
            <v-card-title>
              {{lastThreeMonths[1]}}
            </v-card-title>
            <v-card-subtitle>
              Сваляне на фиша в PDF
            </v-card-subtitle>
            <v-card-text>
              <v-card outlined>
                <v-card-text v-html='lastThreeMonthsStats[1]'>
                </v-card-text>
              </v-card>
            </v-card-text>
            <v-card-actions>
              <v-btn :href='genLink(1)' target='_blank' block color='primary'>Свали</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col cols='12' md='4'>
          <v-card>
            <v-card-title>
              {{lastThreeMonths[2]}}
            </v-card-title>
            <v-card-subtitle>
              Сваляне на фиша в PDF
            </v-card-subtitle>
            <v-card-text>
              <v-card outlined>
                <v-card-text v-html='lastThreeMonthsStats[2]'>
                </v-card-text>
              </v-card>
            </v-card-text>
            <v-card-actions>
              <v-btn :href='genLink(2)' target='_blank' block color='primary'>Свали</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'Payslips',
  data: function () {
    return {
      monthNames: this.$store.state.monthNames,
      lastThreeMonths: ['...', '...', '...'],
      lastThreeMonthsStats: ['...', '...', '...']
    }
  },
  methods: {
    getMonthName (month) {
      if (month > 0)
        return this.monthNames[month]
      else {
        return this.monthNames[12+month]
      }
    },
    getLastThreeMonths () {
      var date = new Date()
      var month = date.getMonth()+1
      this.lastThreeMonths = [this.getMonthName(month-1), this.getMonthName(month-2), this.getMonthName(month-3)]
    },
    genLink (month) {
      return 'https://www.t-service.bg/atbg/api/get_payslip/?apiKey=54321&month='+month+'&key='+this.loginKey
    },
    nlToBr(text)  {
      return text.replace(/\n/g, '<br/>')
    },
    getStats () {
      var userData = new FormData()
      userData.append('apiKey', '54321')
      userData.append('key', this.loginKey)
      this.$http.post('https://www.t-service.bg/atbg/api/get_stats', userData).then((response) => {
        this.lastThreeMonthsStats = [this.nlToBr(response.data[0].report), this.nlToBr(response.data[1].report), this.nlToBr(response.data[2].report)]
      })
    }
  },
  computed: {
    loginKey () {
      return this.$store.state.loginKey
    },
  },
  created () {
    this.getLastThreeMonths()
    this.getStats()
  }
}
</script>
