<template>
  <v-row>
    <v-col cols='12'>
      <v-row justify='center' cols='12'>
        <v-col cols='12' class='text-center'>
          <div class="text-h5 text-md-h4 text-lg-h3 font-weight-bold mb-3">
            Management Notices
          </div>
        </v-col>
      </v-row>
      <v-row justify='center'>
        <v-col :key='id' v-for='(notice, id) in notices' cols='12' md='4'>
          <v-card>
            <v-card-text v-if='notice.read == "0"' class='text-center font-weight-bold primary--text'>
              НЕПРОЧЕТЕНО
            </v-card-text>
            <v-divider></v-divider>
            <v-card-title>
              {{notice.title}}
            </v-card-title>
            <v-card-subtitle>
              <div>Published: {{notice.date_issued}}</div>
              <div>From: {{notice.issuer}}</div>
            </v-card-subtitle>
            <v-card-text>
              <v-card outlined>
                <v-card-text>
                  {{notice.description}}
                </v-card-text>
              </v-card>
            </v-card-text>
            <v-card-actions>
              <v-btn :href='notice.url' target='_blank' block color='primary' @click='markAsRead(notice.file_id)'>Свали</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'Payslips',
  data: function () {
    return {
      notices: [],
    }
  },
  methods: {
    loadNotices (cb = () => {}) {
      this.$http.get('https://inv.bgair.eu/app/a8g/index.php?ep=mm_notes2&staff_id='+this.staffId).then((response) => {
        this.notices = response.data
        cb()
      })
    },

    markAsRead (id) {
      this.$http.get('https://inv.bgair.eu/app/a8g/index.php?ep=mm_note_mark_read&staff_id='+this.staffId+"&file_id="+id).then((response) => {
        this.loadNotices(() => {
          let containsUnread = (this.notices.filter(notice => {return notice.read == "0"}).length > 0)
          if (!containsUnread)
            this.checkForUnreadNotices()
        })
      })
    },

    checkForUnreadNotices: function () {
      this.$store.state.hasUnreadNotices = false
      let linkToGo = ''

      let req1 = this.$http.get('https://inv.bgair.eu/app/a8g/index.php?ep=mm_notes2&staff_id='+this.staffId).then((response) => {
        let containsUnread = (response.data.filter(notice => {return notice.read == "0"}).length > 0)
        if (containsUnread) {
          console.log('notices has unread')
          this.$store.state.hasUnreadNotices = true
          linkToGo = 'notices'
        }
      })

      let req2 = this.$http.get('https://inv.bgair.eu/app/a8g/index.php?ep=ceo_ordinance2&staff_id='+this.staffId).then((response) => {
        let containsUnread = (response.data.filter(notice => {return notice.read == "0"}).length > 0)
        if (containsUnread) {
          console.log('oridinance has unread')
          this.$store.state.hasUnreadNotices = true
          linkToGo = 'ordinance'
        }
      })

      Promise.all([req1, req2]).then(() => {
        if (this.$store.state.hasUnreadNotices)
          this.$router.push(`/${linkToGo}`)
      })
    }
  },
  computed: {
    staffId () {
      return this.$store.state.staffId
    }
  },
  created () {
    this.loadNotices()
  }
}
</script>
