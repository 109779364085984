<template>
<v-row cols='12'>
    <v-col cols='12'>
        <v-card>
            <v-toolbar color='primary' dark>
                <v-toolbar-title>
                    Документи за сваляне
                </v-toolbar-title>
            </v-toolbar>
            <v-list subheader two-line>
                <v-list-item
                    v-for='file in files'
                    :key='file.link'
                    :href='file.link'
                >
                    <v-list-item-avatar>
                        <v-icon
                            class="primary lighten-1"
                            dark
                        >
                            mdi-file-document
                        </v-icon>
                    </v-list-item-avatar>

                    <v-list-item-content>
                    <v-list-item-title v-text="file.name"></v-list-item-title>

                    <v-list-item-subtitle v-text="file.date"></v-list-item-subtitle>
                    </v-list-item-content>

                    <v-list-item-action>
                    <v-btn icon :href='file.link' target="_blank">
                        <v-icon color="primary lighten-1">mdi-download</v-icon>
                    </v-btn>
                    </v-list-item-action>
                </v-list-item>
            </v-list>
        </v-card>
    </v-col>
</v-row>
</template>
<script>
export default {
    name: 'Downloads',
    data: function () {
        return {
            files: [
                {
                    "link": "...",
                    "name": "...",
                    "date": "..."
                }
            ],
        }
    },
    computed: {
        loginKey () {
            return this.$store.state.loginKey
        }
    },
    mounted () {
        this.$http.get('https://t-service.bg/atbg/api/get_downloads?apiKey=54321').then((response) => {
            this.files = response.data
        })
    }
}
</script>