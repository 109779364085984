<template>
    <v-row justify="center" cols='12'>
        <v-col cols='12' md="6">
            <Transition mode="out-in" name="slide-up">
                <v-card v-if="toolCheckStatus == null">
                    <v-toolbar :color='successful == null ? "black" : (successful ? "success" : "primary")' dark>
                        <v-icon class="mr-3">mdi-toolbox</v-icon>
                        <v-toolbar-title>
                            Toolbox Daily Check
                        </v-toolbar-title>
                    </v-toolbar>
                    <v-card-text>
                        <div class="text-h6">Please check the appropriate option:</div>
                        <v-divider></v-divider>
                        <div class="text-h6 green--text">Successful</div>
                        <div class="text-h7 font-weight-bold">Инстументът е наличен/ The tool is available</div>
                        <div>Аз, {{userFullName}}, потвърждавам , че заведеният на мое име персонален и служебен инструмент с номер {{toolsetNumber}} е наличен, правилно съхранен и в изправно състояние.Актуален опис с личния инструмент е предоставен съгласно процедурите в Аеро Техник БГ OOTO.</div>
                        <div>I, {{userFullName}}, confirm that the registered in my name personal and official toolbox number {{toolsetNumber}} is available, properly stored and in working order.Current check list of the personal tool is provided according to the Aero Technic BG MOE procedures.</div>
                        <v-divider></v-divider>
                        <div class="text-h6 red--text">Unsuccessful</div>
                        <div class="text-h7 font-weight-bold">Инструментът НЕ е наличен / The tool is NOT available</div>
                        <div>Аз, {{userFullName}}, потвърждавам, че заведеният на мое име персонален , служебен инструмент с номер {{toolsetNumber}} има установена липса.The tool is NOT available</div>
                        <div>I, {{userFullName}}, confirm that a lack has been found in the personal instrument number {{toolsetNumber}} registered in my name</div>
                        <v-divider></v-divider>
                        <v-radio-group v-model="successful" v-if="lastDailyCheck != null">
                            <template v-slot:label>
                                The check was:
                            </template>
                            <v-radio
                                :label="lastDailyCheck ? 'Successful' : ' Last check failed! Resolve thru the store'"
                                :value="true"
                                :disabled="lastDailyCheck === false"
                            ></v-radio>
                            <v-radio
                                label="Unsuccessful"
                                :value="false"
                            ></v-radio>
                        </v-radio-group>
                        <v-expand-transition>
                            <v-textarea v-model="note" v-show="successful != null" :label="successful ? 'Write your remarks here if needed' : 'A DESCRIPTION OF THE LOST INSTRUMENT IS REQUIRED !!!'" outlined :rows="3" />
                        </v-expand-transition>
                        <v-alert v-if="successful === false" type="warning" color="red" dark>
                            <div>Възможността за успешна проверка на инструментите ще бъде блокирана до разрешаване на случая чрез отключване през инструменталната!</div>
                            <v-divider class="my-2"></v-divider>
                            <div>The ability to successfully check the toolbox will be blocked until the case is resolved by unlocking through the the tool store!</div>
                        </v-alert>

                    </v-card-text>
                    <v-expand-transition>
                        <v-card-actions v-show="successful != null && (note.length >= 3 || successful === true)">
                            <v-btn text @click="submit">Send</v-btn>
                        </v-card-actions>
                    </v-expand-transition>
                </v-card>
                <div v-if="toolCheckStatus == 'success'" class="text-center text-h4 green--text">
                    Thank you! Your Tool Check was sent
                </div>
                <div v-if="toolCheckStatus == 'fail'" class="text-center text-h4 red--text">
                    An error occurred
                </div>
            </Transition>
        </v-col>
    </v-row>
</template>
<script>
export default {
    name: 'ToolboxDailyCheck',
    data: function () {
        return {
            toolCheckStatus: null,
            successful: null,
            note: ""
        }
    },
    computed: {
        loginKey () {
            return this.$store.state.loginKey
        },
        staffId () {
            return this.$store.state.staffId
        },
        lastDailyCheck () {
            return this.$store.state.lastDailyCheck
        },
        userFullName () {
            return `${this.$store.state.userNote} | ${this.$store.state.userNameEn}`
        },
        toolsetNumber () {
            return this.$store.state.toolsetNumber
        },
    },
    mounted () {

    },
    methods: {
        submit () {
            if (this.successful == null)
                return

            const data = new FormData();
            data.append("staff_id", this.staffId)
            data.append("note", this.note)
            data.append("flag_result", this.successful ? "1" : "0")
            this.$http.post("https://inv.bgair.eu/app/a8/index.php?ep=daily_toolcheck", data).then((response) => {
                if (response.data.result == 0) {
                    this.toolCheckStatus = "fail"
                    return
                }

                if (!("result" in response.data)) {
                    this.toolCheckStatus = "fail"
                    return
                }

                this.toolCheckStatus = "success"
            }).catch(error => {
                this.toolCheckStatus = "fail"
            })
        }
    }
}
</script>

<style>
.slide-up-enter-active,
.slide-up-leave-active {
    transition: all 0.25s ease-out;
}

.slide-up-enter-from {
    opacity: 0;
    transform: translateY(30px);
}

.slide-up-leave-to {
    opacity: 0;
    transform: translateY(-30px);
}
</style>