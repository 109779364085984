import Vue from 'vue'
import VueRouter from 'vue-router'

import Distribution from '../views/Distribution.vue'
import Presence from '../views/Presence.vue'
import Workhours from '../views/Workhours.vue'
import Payslips from '../views/Payslips.vue'
import Downloads from '../views/Downloads.vue'
import Notices from '../views/Notices.vue'
import Ordinance from '../views/Ordinance.vue'
import Settings from '../views/Settings.vue'
import CivilContracts from '../views/CivilContracts.vue'
import NonConformity from '../views/NonConformity.vue'
import VoluntaryOccurrence from '../views/VoluntaryOccurrence.vue'
import ToolBoxDailyCheck from '../views/ToolboxDailyCheck.vue'
import ActivityLog from '../views/ActivityLog.vue'
import SafetyReport from '../views/SafetyReport.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/distribution',
    name: 'distribution',
    component: Distribution
  },
  {
    path: '/presence',
    name: 'presence',
    component: Presence
  },
  {
    path: '/workhours',
    name: 'workhours',
    component: Workhours
  },
  {
    path: '/payslips',
    name: 'payslips',
    component: Payslips
  },
  {
    path: '/downloads',
    name: 'downloads',
    component: Downloads
  },
  {
    path: '/notices',
    name: 'notices',
    component: Notices
  },
  {
    path: '/ordinance',
    name: 'ordinance',
    component: Ordinance
  },
  {
    path: '/civilcontracts',
    name: 'civilcontracts',
    component: CivilContracts
  },
  {
    path: '/settings',
    name: 'settings',
    component: Settings
  },
  {
    path: '/nonconformity',
    name: 'nonconformity',
    component: NonConformity
  },
  {
    path: '/voluntaryoccurrence',
    name: 'voluntaryoccurrence',
    component: VoluntaryOccurrence
  },
  {
    path: '/toolboxdailycheck',
    name: 'toolboxdailycheck',
    component: ToolBoxDailyCheck
  },
  {
    path: '/activitylog',
    name: 'activitylog',
    component: ActivityLog
  },
  {
    path: '/safetyreport',
    name: 'safetyreport',
    component: SafetyReport
  },
  {
    path: '/',
    redirect: '/distribution'
  }
]

const router = new VueRouter({
  routes
})

export default router
