<template>
  <v-row>
    <v-col cols='12'>
      <v-dialog
        v-model="showConfirmDialog"
        persistent
        max-width="290"
      >
        <v-card>
          <v-card-title class="text-h5">
            Accept request?
          </v-card-title>
          <v-card-text>You are about to accept a request. Are you sure?</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="red darken-1"
              text
              @click="showConfirmDialog = false"
            >
              Close
            </v-btn>
            <v-btn
              color="green"
              class='white--text'
              @click="acceptContract(confirmRequestId)"
            >
              Accept Request
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      
      <v-dialog
        v-model="showCancelDialog"
        persistent
        max-width="290"
      >
        <v-card>
          <v-card-title class="text-h5">
            Cancel request?
          </v-card-title>
          <v-card-text>You are about to unaccept a request. Are you sure?</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="red darken-1"
              text
              @click="showCancelDialog = false"
            >
              Close
            </v-btn>
            <v-btn
              color="red darken-1"
              class='white--text'
              @click="cancelContract(cancelRequestId)"
            >
              Unaccept Request
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog
        v-model="showNoteDialog"
        persistent
        max-width="290"
      >
        <v-card>
          <v-card-title class="text-h5">
            Note
          </v-card-title>
          <v-card-text><v-textarea v-model='noteDialogText'></v-textarea></v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="red darken-1"
              text
              @click="showNoteDialog = false"
            >
              Cancel
            </v-btn>
            <v-btn
              color="red darken-1"
              text
              @click="updateAvailabilityNote(noteDialogId, noteDialogText)"
            >
              Update
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-row justify='center' cols='12'>
        <v-col cols='12' class='text-center'>
          <div class="text-h5 text-md-h4 text-lg-h3 font-weight-bold mb-3">
            Civil Contracts
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols='12' md='2'>
          <v-card class='legend bt-2 mb-2' outlined>
            <v-card-title>
              Legend
            </v-card-title>
            <v-card-text>
              <div class='legend-row mb-1' v-for='color in legendColors'>
                <div class='legend-row-color mr-3'>
                  <div class='legend-colorbox' :style='{backgroundColor: color.color}'></div>
                </div>
                <div class='legend-row-text'>
                  {{color.text}}
                </div>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols='12' md='10'>
          <vc-date-picker locale="en" :select-attribute='selectAttribute' :drag-attribute='dragAttribute' v-model='selectedRange' ref='mainCalendar' is-range @input='onRangeInput' :attributes='calendarAttributes' is-expanded :first-day-of-week="2" @dayclick='onDayClick' />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols='12' md='12'>
          <v-card class='mt-2'>
            <v-toolbar color='primary' dark>
                <v-toolbar-title>
                    Availability
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn v-if='!createMode' icon>
                  <v-icon @click='startCreateMode'>mdi-plus</v-icon>
                </v-btn>

                <v-btn v-if='createMode' icon>
                  <v-icon @click='startCreateMode'>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-list subheader two-line>
                <v-list-item v-if='!availability.length'><v-list-item-content>Please specify your availability through clicking on the calendar</v-list-item-content></v-list-item>
                <v-list-item
                    v-for='a in availability'
                    :key='a.id'
                    :class="{'red lighten-4': (editAvailability == a.id)}"
                >

                    <v-list-item-content>
                      <v-list-item-title>{{new Date(a.from.split(' ')[0]).toDateString()}} - {{new Date(a.to.split(' ')[0]).toDateString()}}</v-list-item-title>
                      <!-- <v-list-item-subtitle v-text="file.date"></v-list-item-subtitle> -->
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-btn @click='startEditMode(a.id)' icon>
                        <v-icon color="primary lighten-1">mdi-pencil</v-icon>
                      </v-btn>
                    </v-list-item-action>
                    <v-list-item-action>
                      <v-btn @click='removeAvailability(a.id)' icon>
                        <v-icon color="primary lighten-1">mdi-delete</v-icon>
                      </v-btn>
                    </v-list-item-action>
                    <v-list-item-action>
                      <v-btn @click='showAvailabilityNote(a.id)' icon>
                        <v-icon color="primary lighten-1">mdi-information</v-icon>
                      </v-btn>
                    </v-list-item-action>
                </v-list-item>
            </v-list>
        </v-card>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'CivilContracts',
  data: function () {
    return {
      legendColors: [
        // {color: '#bee3f8', text: 'Availability'},
        {color: '#feebc8', text: 'Contract Request'},
        {color: '#44dd44', text: 'Accepted Contract'},
        // {color: '#fed7d7', text: 'Edit Selection'},
        {color: '#c3dafe', text: 'Create Selection'}
      ],

      selectedRange: null,
      availability: [],
      request: [],

      showConfirmDialog: false,
      confirmRequestId: null,

      showCancelDialog: false,
      cancelRequestId: null,

      showNoteDialog: false,
      noteDialogId: 0,
      noteDialogText: '',

      editAvailability: null,

      createMode: false
    }
  },
  methods: {
    onRangeInput: function (range) {
      if (this.createMode) { // New
        this.addAvailability(range)
        return
      }
      if (this.editAvailability) { // Edit
        this.updateAvailability(this.editAvailability, range)
        this.editAvailability = null
      }

      // setTimeout(() => { // hack
      //   this.selectedRange = null
      // }, 100);
    },

    getAvailability: function () {
      var loginData = new FormData()
      loginData.append('apiKey', 54321)
      loginData.append('key', this.loginKey)
      this.$http.post('https://t-service.bg/atbg/api/get_civil_contract_availability', loginData).then((response) => {
        this.availability = response.data.map(a => {return ({'id': a.id, 'from': a.date_from, 'to': a.date_to, 'note': a.note})})
        this.selectedRange = null
        // console.log(this.$refs.mainCalendar)
      })
    },

    getAvailabilityData: function (id) {
      if (id)
        return this.availability.filter(a => {return a.id == id})[0]
      return ''
    },

    updateAvailabilityNote: function (id, note) {
      var loginData = new FormData()
      loginData.append('apiKey', 54321)
      loginData.append('key', this.loginKey)
      loginData.append('date_from', this.getAvailabilityData(id).from)
      loginData.append('date_to', this.getAvailabilityData(id).to)
      loginData.append('note', note)
      loginData.append('aid', id)
      this.$http.post('https://t-service.bg/atbg/api/update_civil_contract_availability', loginData).then((response) => {
        this.showNoteDialog = false
        this.noteDialogId = 0
        this.noteDialogText = ''
        this.getAvailability()
      })
    },

    getRequest: function (cb = () => {}) {
      var loginData = new FormData()
      loginData.append('apiKey', 54321)
      loginData.append('key', this.loginKey)
      this.$http.post('https://t-service.bg/atbg/api/get_civil_contract_request', loginData).then((response) => {
        this.request = response.data.map(a => {return ({'from': a.date_from, 'to': a.date_to, 'id': a.id, 'accepted': a.accepted})})
        cb()
      })
    },

    onDayClick: function (day) {
      let isRequestedDay = day.attributes.filter(a => {return a.key.includes('request')}).length > 0
      if (isRequestedDay) {
        let requestId = day.attributes.filter(a => {return a.key.includes('request')})[0].key.split('-')[1]
        let request = this.request.filter(a => {return a.id == requestId})[0]
        if (request.accepted == '0') {
          this.showConfirmDialog = true
          this.confirmRequestId = requestId
        }
        else if (request.accepted == '1') {
          this.showCancelDialog = true
          this.cancelRequestId = requestId
        }
      }
    },

    acceptContract: function (requestId) {
      var loginData = new FormData()
      loginData.append('apiKey', 54321)
      loginData.append('key', this.loginKey)
      loginData.append('rqid', requestId)
      this.$http.post('https://t-service.bg/atbg/api/accept_civil_contract_request', loginData).then((response) => {
        this.getRequest(() => {
          this.showConfirmDialog = false
        })
      })
    },

    cancelContract: function (requestId) {
      var loginData = new FormData()
      loginData.append('apiKey', 54321)
      loginData.append('key', this.loginKey)
      loginData.append('rqid', requestId)
      this.$http.post('https://t-service.bg/atbg/api/reject_civil_contract_request', loginData).then((response) => {
        this.getRequest(() => {
          this.showCancelDialog = false
        })
      })
    },

    addAvailability: function (range) {
      this.createMode = false
      var loginData = new FormData()
      loginData.append('apiKey', 54321)
      loginData.append('key', this.loginKey)
      loginData.append('date_from', new Date(range.start).toJSON().slice(0, 10))
      loginData.append('date_to', new Date(range.end).toJSON().slice(0, 10))
      loginData.append('note', '')
      this.$http.post('https://t-service.bg/atbg/api/create_civil_contract_availability', loginData).then(() => {
        this.getAvailability()
      })
    },

    updateAvailability: function (id, range) {
      var loginData = new FormData()
      loginData.append('apiKey', 54321)
      loginData.append('key', this.loginKey)
      loginData.append('aid', id)
      loginData.append('date_from', new Date(range.start).toJSON().slice(0, 10))
      loginData.append('date_to', new Date(range.end).toJSON().slice(0, 10))
      loginData.append('note', this.getAvailabilityData(id).note)
      this.$http.post('https://t-service.bg/atbg/api/update_civil_contract_availability', loginData).then(() => {
        this.getAvailability()
      })
    },

    removeAvailability: function (id) {
      if (confirm('Are you sure?')) {
        var loginData = new FormData()
        loginData.append('apiKey', 54321)
        loginData.append('key', this.loginKey)
        loginData.append('aid', id)
        this.$http.post('https://t-service.bg/atbg/api/delete_civil_contract_availability', loginData).then(() => {
          this.getAvailability()
        })
      }
    },

    showAvailabilityNote: function (id) {
      this.showNoteDialog = true
      this.noteDialogId = id
      this.noteDialogText = this.getAvailabilityData(id).note
    },

    startCreateMode: function() {
      this.selectedRange = null
      this.editAvailability = null

      if (this.createMode)
        this.createMode = false
      else
        this.createMode = true
    },

    startEditMode: function (id) {
      this.selectedRange = null
      this.createMode = false
      if (this.editAvailability == id)
        this.editAvailability = null
      else
        this.editAvailability = id
    }

  },
  computed: {
    loginKey () {
      return this.$store.state.loginKey
    },

    calendarAttributes () {
      let availabilities = this.availability.map(a => {
        return ({
          highlight: 'blue',
          content: 'blue',
          dates: {
            start: new Date(a.from),
            end: new Date(a.to)
          },
          key: a.id
          
        })
      })

      let requests = this.request.map(a => {
        return ({
          highlight: a.accepted == "1" ? { start: { style: { background: '#019301' } } , end: { style: { background: '#019301' } }, base: { style: { background: '#44dd44' } } } : 'orange',
          content: a.accepted == "1" ? { start: { style: { background: '#019301' } } , end: { style: { background: '#019301' } }, base: { style: { background: '#44dd44' } } } : 'orange',
          dates: {
            start: new Date(a.from),
            end: new Date(a.to)
          },
          key: 'request-'+a.id
          
        })
      })

      return [
        ...availabilities,
        ...requests
      ]
    },

    selectAttribute() {
      
      if (this.createMode) {
        return {
          highlight: 'purple'
        }
      }

      if (this.editAvailability) {
        return {
          highlight: 'red'
        }
      }

      return {
        highlight: {
          style: {
            backgroundColor: 'transparent'
          }
        }
      } 
    },

    dragAttribute() {
      if (this.createMode) {
        return {
          highlight: 'indigo'
        }
      }

      if (this.editAvailability) {
        return {
          highlight: 'red'
        }
      }

      return {
        highlight: {
          style: {
            backgroundColor: 'transparent'
          }
        }
      } 
    }
  },
  created () {
    this.getAvailability()
    this.getRequest()
  }
}
</script>
<style>
  .legend-row {
    display: flex;
    flex-flow: row nowrap;
  }
  .legend {
  }
  .legend-colorbox {
    width: 20px;
    height: 20px;
  }
</style>