var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-card-title',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Търсене по дата (ГГГГ-ММ-ДД)","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.presenceData,"items-per-page":-1,"search":_vm.search,"loading":_vm.loadingPresence,"header-props":{sortByText: 'Сортиране'},"hide-default-footer":true,"sort-by":"checkdate","sort-desc":true},scopedSlots:_vm._u([{key:"item.checkdate",fn:function(ref){
var item = ref.item;
return [_c('span',{domProps:{"innerHTML":_vm._s(item.checkdate)}})]}},{key:"item.amount9",fn:function(ref){
var item = ref.item;
return [_c('span',[_c('v-chip',{attrs:{"color":"primary"}},[_vm._v(_vm._s(_vm.formatFloat(item.amount9)))])],1)]}},{key:"item.payable_days9",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatFloat(item.payable_days9)))])]}},{key:"item.autocheckout",fn:function(ref){
var item = ref.item;
return [_c('v-simple-checkbox',{attrs:{"value":!!parseInt(item.autocheckout),"disabled":""}})]}},{key:"item.cert",fn:function(ref){
var item = ref.item;
return [_c('v-simple-checkbox',{attrs:{"value":!!parseInt(item.cert),"disabled":""}})]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }