<template>
  <v-row>
    <v-col cols='12'>
      <v-card>
        <v-card-title>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Търсене по дата (ГГГГ-ММ-ДД)"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="presenceData"
          :items-per-page="-1"
          class="elevation-1"
          :search='search'
          :loading='loadingPresence'
          :header-props="{sortByText: 'Сортиране'}"
          :hide-default-footer='true'
          sort-by="checkdate"
          :sort-desc="true"
        >
        <template v-slot:item.checkdate="{ item }">
          <span v-html="item.checkdate"></span>
        </template>
        <template v-slot:item.amount9="{ item }">
          <span><v-chip color='primary'>{{formatFloat(item.amount9)}}</v-chip></span>
        </template>
        <template v-slot:item.payable_days9="{ item }">
          <span>{{formatFloat(item.payable_days9)}}</span>
        </template>
        <template v-slot:item.autocheckout="{ item }">
          <v-simple-checkbox
            :value="!!parseInt(item.autocheckout)"
            disabled
          ></v-simple-checkbox>
        </template>
        <template v-slot:item.cert="{ item }">
          <v-simple-checkbox
            :value="!!parseInt(item.cert)"
            disabled
          ></v-simple-checkbox>
        </template>
        </v-data-table>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'Presence',
  data: function () {
    return {
      headers: [
        { value: 'checkdate', text: 'Дата'},
        { value: 'autocheckout', text: 'Авт. чекаут', filterable: false},
        { value: 'ctype', text: 'Договор', filterable: false},
        { value: 'cert', text: 'Сертифициран', filterable: false},
        { value: 'payable_days9', text: 'Дни за плащане', filterable: false},
        { value: 'rate', text: 'Ставка', filterable: false},
        { value: 'amount9', text: 'Сума за плащане', filterable: false}
      ],
      presenceData: [{
        checkdate: '...',
        autocheckout: '...',
        ctype: '...',
        cert: '...',
        payable_days9: '...',
        rate: '...',
        amount9: '...'
      }],
      search: '',
      loadingPresence: false
    }
  },
  created() {
    this.getPresence()
  },
  computed: {
    loginKey () {
      return this.$store.state.loginKey
    }
  },
  methods: {
    getPresence() {
      this.loadingPresence = true
      var userData = new FormData()
      userData.append('apiKey', '54321')
      userData.append('key', this.loginKey)
      this.$http.post('https://t-service.bg/atbg/api/get_presence', userData).then((response) => {
        this.presenceData = response.data
        this.loadingPresence = false
      })
    },
    formatFloat(number) {
      if (number != '...')
        // return parseInt(number) == 0 ? '0' : Math.round(number * 100) / 100
        return Math.round(number * 100) / 100
      else
        return number;
    }
  }
}
</script>
