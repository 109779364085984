import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    loginKey: null,
    monthNames: {
      1: 'Януари',
      2: 'Февруари',
      3: 'Март',
      4: 'Април',
      5: 'Май',
      6: 'Юни',
      7: 'Юли',
      8: 'Август',
      9: 'Септември',
      10: 'Октомври',
      11: 'Ноември',
      12: 'Декември'
    },
    staffId: null,
    hasUnreadNotices: false,
    lastDailyCheck: null,
    toolsetNumber: "",
    toolboxExpiryDate: "",
    userNameEn: "",
    userNote: ""
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
