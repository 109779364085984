<template>
  <v-container>
    <v-row justify='center' cols='12'>
      <v-col cols='12' class='text-center'>
        <div class="text-h5 text-md-h4 text-lg-h3 font-weight-bold mb-3">
          Activity Log
        </div>
      </v-col>
    </v-row>
    <v-row align="center" justify="center">
      <v-col cols="6" md="1">
        <v-menu
          ref="dateFromMenu"
          v-model="dateFromMenu"
          :close-on-content-click="false"
          :return-value.sync="dateFrom"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="dateFrom"
              label="From"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="dateFrom"
            no-title
            scrollable
          >
            <v-spacer></v-spacer>
            <v-btn
              text
              color="primary"
              @click="dateFromMenu = false"
            >
              Cancel
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="$refs.dateFromMenu.save(dateFrom)"
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="6" md="1">
        <v-menu
          ref="dateToMenu"
          v-model="dateToMenu"
          :close-on-content-click="false"
          :return-value.sync="dateTo"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="dateTo"
              label="To"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="dateTo"
            no-title
            scrollable
          >
            <v-spacer></v-spacer>
            <v-btn
              text
              color="primary"
              @click="dateToMenu = false"
            >
              Cancel
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="$refs.dateToMenu.save(dateTo)"
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="6" md="2">
        <v-select v-model="aircraftType" :items="aircraftTypes"></v-select>
      </v-col>
      <v-col cols="6" md="2">
        <v-select v-model="skill" :items="skills"></v-select>
      </v-col>
      <v-col cols="6" md="2">
        <v-text-field v-model="taskRefFilter" placeholder="Search by Taskref" clearable></v-text-field>
      </v-col>
      <v-col cols="6" md="2">
        <v-text-field v-model="descriptionFilter" placeholder="Search by Description" clearable></v-text-field>
      </v-col>
      <v-col cols="12" md="1">
        <v-btn block @click="fetchData" color="info" :loading="activityLogDataFetching" :disabled="dateFrom == null || dateTo == null">Filter</v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-data-table
          :headers="activityLogTableHeaders"
          :items="activityLogData"
          :items-per-page="15"
          :loading="activityLogDataFetching"
          class="elevation-1"
        ></v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'ActivityLog',
  data: function () {
    return {
      activityLogTableHeaders: [
        { text: 'Name', value: 'fullname' },
        { text: 'Reason', value: 'reason' },
        { text: 'WP', value: 'wpnum' },
        { text: 'A/C', value: 'acreg', width: 100 },
        { text: 'A/C Type', value: 'actype', width: 130 },
        { text: 'A/C Model', value: 'acmodel', width: 130 },
        { text: 'WC #', value: 'wcnum' },
        { text: 'Skill', value: 'skill', width: 100 },
        { text: 'ATA Code', value: 'ataname', width: 130 },
        { text: 'Sub ATA Code', value: 'subata', width: 130 },
        { text: 'Task Ref #', value: 'taskref' },
        { text: 'Description', value: 'description' },
      ],
      activityLogData: [],
      activityLogDataFetching: false,
      dateFrom: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      dateFromMenu: false,
      dateTo: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      dateToMenu: false,
      aircraftTypes: [
        { value: 0, text: "- ALL AC TYPES-" },
        { value: 1, text: "ATR-42" },
        { value: 2, text: "A320" },
        { value: 3, text: "BAe-146" },
        { value: 4, text: "ATR-72" },
        { value: 5, text: "AVRO 146- RJ" },
        { value: 7, text: "ATR42-400/500" },
        { value: 8, text: "AVRO 146-RJ85" },
        { value: 10, text: "B737CL" },
        { value: 11, text: "ATR 72-212A" },
        { value: 12, text: "B737NG" },
        { value: 13, text: "ATR 72-212F" },
        { value: 14, text: "B737CLMSG3" },
        { value: 16, text: "AVRO146-RJ100" },
        { value: 17, text: "A321 (CFM56)" },
        { value: 18, text: "A320-232" },
        { value: 20, text: "A321 (V2500)" },
        { value: 21, text: "A319" },
        { value: 22, text: "A320 (V2500)" }
      ],
      aircraftType: 0,
      skills: [
        { value: 0, text: "- ALL SKILLS-" },
        { value: 1, text: "B1" },
        { value: 2, text: "B1 SP" },
        { value: 3, text: "B2" },
        { value: 4, text: "EL" },
        { value: 5, text: "NDT" },
        { value: 6, text: "B1/B2" },
        { value: 7, text: "WS" },
        { value: 8, text: "B1/NDT" }
      ],
      skill: 0,
      taskRefFilter: "",
      descriptionFilter: ""
    }
  },
  methods: {
    fetchData() {
      this.activityLogDataFetching = true
      this.$http.get(`https://inv.bgair.eu/app/a8g/index.php?ep=staffportal%D0%B3GetActivity&staff_id=${this.staffId}&date_from=${this.dateFrom}&date_to=${this.dateTo}&skill_id=${this.skill}&aircraft_type_id=${this.aircraftType}&taskref=${this.taskRefFilter}&descript=${this.descriptionFilter}`).then((response) => {
        if (!Array.isArray(response.data)) {
          this.activityLogData = []
          return
        }

        this.activityLogData = response.data
      }).finally(() => {
        this.activityLogDataFetching = false
      })
    }
  },
  computed: {
    staffId () {
      return this.$store.state.staffId
    }
  },
  created () {
    this.fetchData()
  }
}
</script>
<style>

</style>