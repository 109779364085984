<template>
  <v-row>
    <v-col cols='12'>
      <!-- <v-row cols='12' justify='center'>
        <v-col cols='12' lg='6'>
          <v-card>
            <v-card-title  class='text-h5'>
              Профил
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <v-row justify='space-between'>
                <v-col sm='6' cols='12' class='text-h6'>Смяна на парола</v-col><v-col cols='12' sm='4'><v-btn block color='primary' dark>Промени</v-btn></v-col>
              </v-row>
              <v-divider />
              <v-row justify='space-between'>
                <v-col sm='6' cols='12' class='text-h6'>Смяна на имейл</v-col><v-col cols='12' sm='4'><v-btn block color='primary' dark>Промени</v-btn></v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row> -->
      <v-row cols='12' justify='center'>
        <v-col cols='12' lg='6'>
          <v-card>
            <v-card-title  class='text-h5'>
              Интерфейс
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <v-row justify='space-between'>
                <v-col sm='6' cols='12' class='text-h6'>Тъмна тема</v-col><v-col cols='12' sm='4'><v-switch @change='updateDarkState' v-model='$vuetify.theme.dark' class='mt-0'>Промени</v-switch></v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'Settings',
  methods: {
    updateDarkState(isDark) {
      localStorage.setItem('isDark', isDark)
        
    }
  }
}
</script>
