<template>
  <v-row>
    <v-col>
      <v-speed-dial absolute v-model="helpMenu" bottom right direction="top" :open-on-hover="true" transition="slide-y-reverse-transition">
        <template v-slot:activator>
          <v-btn v-model="helpMenu" color="orange" dark fab large>
            <v-icon v-if="helpMenu">
              mdi-close
            </v-icon>
            <v-icon v-else>
              mdi-help
            </v-icon>
          </v-btn>
        </template>
        <v-btn target="_blank" href="https://atbgstaff.bgair.eu/voluntary_occ_report_example.pdf" fab large color="blue" dark>
          <v-icon>mdi-file</v-icon>
        </v-btn>
        <v-btn target="_blank" href="https://atbgstaff.bgair.eu/instructions_voluntary_report.pdf" fab large color="green" dark>
          <v-icon>mdi-information-variant</v-icon>
        </v-btn>
      </v-speed-dial>
      <v-form ref="form" v-model="formValid" lazy-validation>
        <v-row no-gutters>
          <v-col :cols="12">
            <div class="text-h6 text-md-h5 text-lg-h4 font-weight-bold text-center">Доброволен доклад за събитие</div>
          </v-col>
          <v-col :cols="12">
            <div class="text-h6 text-md-h5 text-lg-h4 font-weight-bold mb-3 text-center">Voluntary occurence report</div>
          </v-col>
        </v-row>

        <v-row no-gutters>
          <v-col :cols="12">
            <div class="body-2 text-md-h6 text-lg-h5 text-center">Единствената цел на информацията, предоставена по този формуляр, е да се подобри безопасността. Не сте длъжни да давате своята идентичност и позиция в организацията. Ако обаче искате да направите това, те няма да бъдат разкривани без Вашето одобрение.</div>
          </v-col>
          <v-col :cols="12">
            <div class="body-2 text-md-h6 text-lg-h5 mb-3 text-center">The single aim of the information reported on this form is to improve safety. You are not obliged to give your identity and position in the organisation. However, should you wish to do so, they will not be disclosed without your approval.</div>
          </v-col>
        </v-row>
        <v-row>
          <v-col :cols="12" :lg="3">
            <v-autocomplete v-model="aircraftRegistration" :items="aircraftRegistrations" outlined label="Aircraft Registration"></v-autocomplete>
          </v-col>
        </v-row>
        <v-row>
          <v-col :cols="12" :lg="3">
            <v-dialog v-model="showDatePicker" persistent width="290px">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field :rules="[v => !!v || 'Item is required']" outlined v-model="eventDate" label="Date of event" append-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
              </template>
              <v-date-picker v-model="eventDate" scrollable >
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="showDatePicker = false">Cancel</v-btn>
                <v-btn text color="primary" @click="showDatePicker = false">OK</v-btn>
              </v-date-picker>
            </v-dialog>
          </v-col>
        </v-row>
        <v-row>
          <v-col :cols="12">
            <v-text-field outlined v-model="location" label="Location of event"></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col :cols="12">
            <v-text-field outlined disabled value="maintenance" label="Type of operation" ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col :cols="12">
            <v-textarea :rows="2" outlined v-model="documentation" label="Documentation"></v-textarea>
          </v-col>
        </v-row>
        <v-row>
          <v-col :cols="12">
            <v-textarea :rows="2" outlined v-model="tools" label="Tools"></v-textarea>
          </v-col>
        </v-row>
        <v-row>
          <v-col :cols="12">
            <v-autocomplete v-model="eventClassification" :items="eventClassifications" outlined label="Others"></v-autocomplete>
          </v-col>
        </v-row>
        <v-row>
          <v-col :cols="12">
            <v-textarea :rules="[v => !!v || 'Item is required']" outlined v-model="description" label="Description"></v-textarea>
          </v-col>
        </v-row>
        <v-row>
          <v-col :cols="12">
            <v-textarea outlined v-model="suggestions" label="Suggestions"></v-textarea>
          </v-col>
        </v-row>
        <v-row>
          <v-col :cols="12" :lg="2">
            <v-file-input v-model="attachedFiles" prepend-icon="" append-icon="mdi-paperclip" outlined multiple label="Attached files" truncate-length="15"></v-file-input>
          </v-col>
        </v-row>
        <v-row>
          <v-col :cols="12" :lg="2">
            <v-btn @click="submitForm" color="blue" block outlined>Submit</v-btn>
          </v-col>
          <v-col :cols="12" :lg="2">
            <v-btn color="error" block outlined @click="$router.push('/')">Exit without saving</v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-col>
  </v-row>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'VoluntaryOccurrence',
  created () {
    this.getAircraftRegistrations()
    this.getEventClassifications()
  },
  data: function () {
    return {
      eventDate: '',
      reportedBy: 'nikoi',
      showDatePicker: false,
      attachedFiles: [],
      description: '',
      suggestions: '',
      documentation: '',
      tools: '',
      location: '',
      eventClassifications: [],
      eventClassification: null,
      aircraftRegistrations: [],
      aircraftRegistration: null,
      formValid: true,
      helpMenu: false
    }
  },
  computed: {
    loginKey () {
      return this.$store.state.loginKey
    },
  },
  methods: {
    getEventClassifications () {
      this.$http.get("https://inv.bgair.eu/app/a8g/index.php?ep=AutoFilter?t=event_classification&c=name&k=id").then(response => {
        console.log(response.data)
        this.eventClassifications = response.data.map(classification => {
          return {'value': classification.id, 'text': classification.text}
        })
      })
    },
    getAircraftRegistrations () {
      this.$http.get("https://inv.bgair.eu/app/a8g/index.php?ep=AutoFilter?t=aircraft&c=registration&k=id").then(response => {
        console.log(response.data)
        this.aircraftRegistrations = response.data.map(registration => {
          return {'value': registration.id, 'text': registration.text}
        })
      })
    },
    submitForm () {
      if (!this.$refs.form.validate())
        return

      const reqData = new FormData()
      this.attachedFiles.forEach(file => {
        reqData.append("files[]", file, file.name)
      })
      reqData.append("documentation", this.documentation)
      reqData.append("tools", this.tools)
      reqData.append("description", this.description)
      reqData.append("suggestions", this.suggestions)
      reqData.append("event_date", this.eventDate)
      reqData.append("aircraft_id", this.aircraftRegistration)
      reqData.append("event_classification_id", this.eventClassification)
      reqData.append("event_location", this.location)
      reqData.append("event_operation", "maintenance")
      reqData.append("tools", this.tools)
      this.$http.post('https://apih1.aerotechnic-bg.com/voluntary_upload_external/', reqData).then(response => {
        this.$router.push('/')
      })
    }
  }
}
</script>
