<template>
  <v-row>
    <v-col cols='12'>
      <v-card outlined>
        <v-card-text>
          <v-row justify='center' cols='12'>
            <v-col cols='12' class='text-center'>
              <div class="text-h5 text-md-h4 text-lg-h3 font-weight-bold mb-3">
                Вашите отработени часове за месец {{getCurrentMonth().toLowerCase()}}
              </div>
            </v-col>
          </v-row>
          <v-row cols='12'>
            <v-col cols='12' md='4'>
              <v-card color='primary' dark>
                <v-card-title>
                  Присъствени дни
                </v-card-title>
                <v-card-text>
                  <div class='text-h4 text-sm-h3 text-md-h2'>{{presenceDays}} дни</div>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols='12' md='4'>
              <v-card color='primary' dark>
                <v-card-title>
                  Отработени дни
                </v-card-title>
                <v-card-text>
                  <div class='text-h4 text-sm-h3 text-md-h2'>{{formatFloat(workDays)}} дни</div>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols='12' md='4'>
              <v-card color='primary' dark>
                <v-card-title>
                  Отработени часове
                </v-card-title>
                <v-card-text>
                  <div class='text-h4 text-sm-h3 text-md-h2'>{{formatFloat(workHours)}} часа</div>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>

    <v-dialog
      v-model="helpDialog"
      max-width="550"
      :fullscreen="$vuetify.breakpoint.xsOnly"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          fab
          fixed
          bottom
          right
          large
          color="orange"
          dark
          v-bind="attrs"
          v-on="on"
        >
          <v-icon>mdi-help</v-icon>
        </v-btn>
      </template>
      <v-card>
        <v-card-title class="headline">
          Помощ
        </v-card-title>
        <v-card-text>
          <v-divider></v-divider>
          <v-row cols='12'>
            <v-col cols='12'>
              <span class='font-weight-bold'>Присъствени дни</span> - Броят на дните, в които е отбелязано присъствие
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row cols='12'>
            <v-col cols='12'>
              <span class='font-weight-bold'>Отработени дни</span> - Количество на отработените работни дни
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row cols='12'>
            <v-col cols='12'>
              <span class='font-weight-bold'>Отработени часове</span> - Количество на отработените часове
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row cols='12'>
            <v-col cols='12'>
              <v-alert type='warning' class='mb-0' dense outlined text>Информацията е актуална към края на предходния ден</v-alert>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="helpDialog = false"
          >
            Разбрах
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-row>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'Workhours',
  created () {
    this.getWorkhours()
  },
  data: function () {
    return {
      mode: 'days',
      monthNames: this.$store.state.monthNames,
      presenceDays: '...',
      workDays: '...',
      workHours: '...',
      helpDialog: false
    }
  },
  computed: {
    loginKey () {
      return this.$store.state.loginKey
    },
  },
  methods: {
    getCurrentMonth() {
      var date = new Date()
      return this.monthNames[date.getMonth()+1]
    },
    getWorkhours() {
      var userData = new FormData()
      userData.append('apiKey', '54321')
      userData.append('key', this.loginKey)
      this.$http.post('https://t-service.bg/atbg/api/get_workhours', userData).then((response) => {
        this.presenceDays = response.data[0].presence_days
        this.workDays = response.data[0].work_days
        this.workHours = response.data[0].work_hours
      })
    },
    formatFloat(number) {
      if (number != '...')
        return parseInt(number) == 0 ? '0' : Math.round(number * 100) / 100
      else
        return number;
    }
  }
}
</script>
