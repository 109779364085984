<template>
  <v-row class="fill-height">
    <v-col>
      <v-speed-dial absolute v-model="helpMenu" bottom right direction="top" :open-on-hover="true" transition="slide-y-reverse-transition">
        <template v-slot:activator>
          <v-btn v-model="helpMenu" color="orange" dark fab large>
            <v-icon v-if="helpMenu">
              mdi-close
            </v-icon>
            <v-icon v-else>
              mdi-help
            </v-icon>
          </v-btn>
        </template>
        <v-btn target="_blank" href="https://atbgstaff.bgair.eu/nonconf_report_free_example.pdf" fab large color="blue" dark>
          <v-icon>mdi-file</v-icon>
        </v-btn>
        <v-btn target="_blank" href="https://atbgstaff.bgair.eu/instructions_nonconf_report.pdf" fab large color="green" dark>
          <v-icon>mdi-information-variant</v-icon>
        </v-btn>
      </v-speed-dial>
      <v-form ref="form" v-model="formValid" lazy-validation>
        <v-row no-gutters>
          <v-row no-gutters>
            <v-col :cols="12">
              <div class="text-h6 text-md-h5 text-lg-h4 font-weight-bold text-center">Доклад за несъответствие</div>
            </v-col>
            <v-col :cols="12">
              <div class="text-h6 text-md-h5 text-lg-h4 font-weight-bold mb-3 text-center">Nonconformity report</div>
            </v-col>
          </v-row>

          <v-row no-gutters>
            <v-col :cols="12">
              <div class="body-2 text-md-h6 text-lg-h5 text-center">Да се попълни от открилият несъответствието и да се изпрати до СУК</div>
            </v-col>
            <v-col :cols="12">
              <div class="body-2 text-md-h6 text-lg-h5 mb-3 text-center">To be completed by the one who found the nonconformity and to be transmitted to QMS</div>
            </v-col>
          </v-row>
        </v-row>
        <v-row>
          <v-col :cols="12">
            <v-textarea :rules="[v => !!v || 'Item is required']" outlined v-model="description" label="Description"></v-textarea>
          </v-col>
        </v-row>
        <v-row>
          <v-col :cols="6" :lg="3">
            <v-dialog v-model="showDatePicker" persistent width="290px">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field outlined v-model="reportedOn" label="Reported on" append-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
              </template>
              <v-date-picker v-model="reportedOn" scrollable >
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="showDatePicker = false">Cancel</v-btn>
                <v-btn text color="primary" @click="showDatePicker = false">OK</v-btn>
              </v-date-picker>
            </v-dialog>
          </v-col>
          <v-col :cols="6" :lg="4">
            <v-text-field v-model="reportedBy" outlined label="Reported by"></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col :cols="12" :lg="2">
            <v-file-input v-model="attachedFiles" prepend-icon="" append-icon="mdi-paperclip" outlined multiple label="Attached files" truncate-length="15"></v-file-input>
          </v-col>
        </v-row>
        <v-row>
          <v-col :cols="12" :lg="2">
            <v-btn @click="submitForm" color="blue" block outlined>Submit</v-btn>
          </v-col>
          <v-col :cols="12" :lg="2">
            <v-btn color="error" block outlined @click="$router.push('/')">Exit without saving</v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-col>
  </v-row>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'NonConformity',
  created () {
  },
  data: function () {
    return {
      reportedOn: '',
      reportedBy: '',
      showDatePicker: false,
      attachedFiles: [],
      description: '',
      formValid: true,
      helpMenu: false
    }
  },
  computed: {
    loginKey () {
      return this.$store.state.loginKey
    },
  },
  methods: {
    submitForm () {
      if (!this.$refs.form.validate())
        return

      const reqData = new FormData()
      this.attachedFiles.forEach(file => {
        reqData.append("files[]", file, file.name)
      })
      reqData.append("description", this.description)
      reqData.append("date_received", this.reportedOn)
      reqData.append("inspector", this.reportedBy)
      this.$http.post('https://apih1.aerotechnic-bg.com/nonconf_upload_external/', reqData).then(response => {
        this.$router.push('/')
      })
    }
  }
}
</script>
<style>
</style>