<template>
  <v-row>
    <v-col cols='12'>
      <v-row justify='center' cols='12'>
        <v-col cols='12' class='text-center'>
          <div class="text-h5 text-md-h4 text-lg-h3 font-weight-bold mb-3">
            Разпределение
          </div>
        </v-col>
      </v-row>
      <v-row cols='12'>
        <v-col cols='12'>
          <v-card>
            <v-card-title>
              {{name}}
            </v-card-title>
            <v-card-subtitle>
              {{subname}}
            </v-card-subtitle>
            <v-card-text v-html='announcement'>
              
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'Distribution',
  data: function () {
    return {
      name: '...',
      subname: '...',
      announcement: '...',
      types: [
        'Project Leaders',
        'Team Leaders',
        '',
        'Mechanics CRS',
        'Mechanics Non CRS',
        'Trainees',
        'Avionics CRS',
        'Avionics Non CRS',
        'Engineer (Planning)',
        'Other Staff',
        'Contractor',
        'Metal Sheet',
        'NDT',
        'Instructor'
      ]
    }
  },
  computed: {
    loginKey () {
      return this.$store.state.loginKey
    },
  },
  created() {
    this.getDistribution()
  },
  methods: {
    getDistribution() {
      var userData = new FormData()
      userData.append('apiKey', '54321')
      userData.append('key', this.loginKey)
      this.$http.post('https://www.t-service.bg/atbg/api/get_distribution', userData).then((response) => {
        this.name = response.data[0].name
        this.subname = this.types[response.data[0].type]
        this.announcement = response.data[0].announcement.replace(/\n/g, '<br/>')
      })
    }
  }
}
</script>
