<template>
  <v-row class="text-center" justify='center'>
    <!-- <v-col cols="12">
      <v-img
        :src="require('../assets/logo.png')"
        class="my-3"
        contain
        height="200"
      />
    </v-col> -->

    <v-col class="mb-4" cols='12'>
      <h1 class="text-h4 text-md-h3 font-weight-bold mb-3">
        Добре дошли!
      </h1>
    </v-col>

    <v-col class='mb-5' cols='12' sm='8' md='6' lg='3'>
      <v-card outlined :loading='loading'>
        <v-card-text>
          <v-form ref="form" v-model="loginValid" lazy-validation>
            <v-text-field prepend-icon='mdi-account' v-model="username" :counter="10" :rules="usernameRules" label="Потребителско име" required></v-text-field>

            <v-text-field prepend-icon='mdi-key' v-model="password" :rules="passwordRules" type='password' label="Парола" required></v-text-field>

            <v-btn :disabled="!loginValid" color="primary" class="mt-4" block @click='emitLogin'>Вход</v-btn>

            <v-alert v-if='incorrectAttempt' transition="slide-y-transition" type='error' class='mt-4' dense outlined>Грешно потребителско име или парола!</v-alert>
          </v-form>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
  export default {
    name: 'LoginForm',
    props: {
      'loading': {default: false},
      'incorrectAttempt': {default: false}
    },
    data: () => ({
      username: '',
      password: '',
      loginValid: false,
      usernameRules: [
        v => !!v || 'Потребителското име е задължително',
        // v => v.length == 10 || 'ЕГН-то трябва да е 10 символа',
      ],
      passwordRules: [
        v => !!v || 'Паролата е задължителна'
      ],
    }),
    methods: {
      emitLogin: function () {
        this.$emit('login', this.username, this.password)
        this.$refs.form.validate()
      }
    }
  }
</script>
